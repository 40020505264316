import { Cell, CellOptions, Grid, Space } from "./base"

const golUpdator = (space: Space) => {
    if (space.cell && !(space.influence.amount === 2 || space.influence.amount === 3)) {
        // death
        space.cell = undefined
    } else if (!space.cell && space.influence.amount === 3) {
        // birth
        space.cell = new GOLCell()
    }
}

class GOLCell extends Cell {
    constructor(options: CellOptions = {}) {
        super(options)
        this.color = "black"
        this.updator = golUpdator
    }
}

export class GOLGrid extends Grid {
    constructor(size) {
        super(size)
    }

    getNewCell(): Cell {
        return new GOLCell()
    }
}