import { h } from 'preact';

import Loader from '~/components/Loader';
import Card from '~/components/Card';

import useRecipes from './useRecipes';
import styles from './Recipes.module.css';

const Recipes = () => {
	const { recipes, loading } = useRecipes();

	return (
		<div className={styles.root}>
			{ loading && <Loader />}
			{ recipes.map((recipe) => (
				<Card 
					className={styles.card}
					title={recipe.name} 
					description={recipe.description}
					link={`/me/recipes/${recipe.slug}`} 
					linkText="See Full Recipe" 
				/>
			))}
		</div>
	);
};

export default Recipes;
