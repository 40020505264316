import { h } from 'preact';
import FullExpansion from "~/components/FullExpansion";
import { Link } from 'preact-router/match';

const panels = {
    'AlexBrausen.com': <div>
        <p>This is a personal website, mainly used as a way to experiment with different technologies and make something hopefully interesting.</p>

        <h4>Stack</h4>
        <h5>Frontend</h5>
        <p>A Preact.js client is built using functional components and a few MaterialUI components (which I hope to transition to use `styled-components` soon). The `preact-cli` is used for building into production assets.</p>
        <h5>Backend</h5>
        <p>A simple Node.js server built with express is used to serve these static files, and also exposes an api for the client to fetch info via REST calls.</p>

        <h4>Deployment</h4>
        <p>I'm currently deploying this site through Digital Ocean and it's Kubernetes support. The Kubernetes cluster deploys the above Stack into pods and uses a LoadBalancer, Ingress control and the cert-manager project to securely run the site in a way that is easy to do continuous integration and deployment (CI/CD) by updating the docker image and deployment spec.</p>

        <h4>Future</h4>
        <p>There's a lot that I would hope to do with this site, but it's hard to find time do so. Here's a list of potential improvements in a roughly priority-based order:</p>
        <ul>
            <li>Add the ability to interact with the playlist in intuitive or meaningful ways (sorting, voting on the songs that are in the playlist, etc.)</li>
            <li>Add features to the Game of Life app such as Toggling cells, changing the rate of time, and using a seed for sharing starting configs</li>
            <li>Review the youtube channels listed, giving links to specific videos that have content I find interesting.</li>
        </ul>
    </div>,
    Snake: <div>
        <p class="col-lg-4">
            This is the first large personal project I ever made and the inspiration for it came from the fact
            that I had never been able to program anything that had a visual aspect yet in my courses.
            <br />
            <br />Snake is a
            game based on the well known version where the user is an ever growing 'snake' trying to grow as large
            as possible while avoiding obstacles in a confined world. My version has added the ability to play a
            local 2 player mode where the goal is to survive longer than the opponent. This adds a more competitive
            nature to the originally solo gameplay. In addition, my version allows a slightly more interesting solo
            gameplay where, as you grow, mines are placed in the world to add more obstacles.
            <br />
            <br />The source code for this can be found on <a href="https://github.com/Brausen42/Snake">my github</a>
        </p>
    </div>,
    'Game of Life': <div>
        <p class="col-lg-8">
            My interest in Cellular Automata lead me to recreate the <a href="https://en.wikipedia.org/wiki/Conway%27s_Game_of_Life" target="_blank" rel="noopener noreferrer">Game of Life</a> introduced by John Conway in 1970.
            The idea is that you can create a simple world that advances from one time step to the next by applying a few
            simple rules to the current state of the world, and out of it there precipitates complex interactions.
            <br />
            <br />I am currently working on a <Link href="/me/gol/">web-based implementation</Link>.
            This version is still in very early development so it's possible that there's some bugs.
            <br />
        </p>
    </div>
}

export default () => <FullExpansion panels={panels} />