import { h } from "preact";
import { useEffect } from "preact/hooks";
import { Box, List, ListItem, styled, Typography } from "@material-ui/core";

import ExternalLink from "~/components/ExternalLink";
import Loader from "~/components/Loader";

import styles from "./Recipe.module.css";
import useRecipes from "../useRecipes";

const ListIndex = styled(Typography)({
	background: 'aliceblue',
	color: 'slategray',
	boxShadow: 'slategray 0px 0px 3px 0.1px',
	borderRadius: '50%',
	minWidth: '30px',
	width: '30px',
	height: '30px',
	display: 'inline-flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginRight: '10px'
});

interface RecipeProps {
    slug: string;
}

export default ({ slug }) => {
    const { selectedRecipe, setSelectedRecipeSlug } = useRecipes();

    useEffect(() => {
        setSelectedRecipeSlug(slug);
    }, [slug]);

    if (!selectedRecipe) {
        return <Loader />;
    }

    const { name, description, ingredients, steps, url } = selectedRecipe;

    return (
        <Box width="100%">
            <div className={styles.back}>
                <a href="/me/recipes">See All Recipes</a>
            </div>
            <h2>{name}</h2>
            <p>{description}</p>
            { url && <div><ExternalLink href={url}>See original recipe</ExternalLink></div> }
            <List component="ol">
                {ingredients.map((ingredient) => (
                    <ListItem>{ingredient}</ListItem>
                ))}
            </List>
            <List component="ol">
                {steps.map(({ order, description }) => (
                    <ListItem><ListIndex>{order}</ListIndex>{description}</ListItem>
                ))}
            </List>
        </Box>
    )
};