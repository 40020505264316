const IS_DEV = process.env.NODE_ENV === "development"

export const getUrl = assetPath => IS_DEV ? `/${assetPath}` : `${process.env.ASSET_PATH_ROOT}${assetPath}`

export const getApiUrl = apiPath => IS_DEV ? `http://localhost:8080${apiPath}` : apiPath

export const getCRSFToken = () => {
    const cookies = document.cookie.split(";")
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i]
        if (cookie.startsWith("csrftoken=")) {
            return cookie.substring(10)
        }
    }
    return null
}