import classNames from "classnames";
import { h } from "preact";

import Link from "~/components/Link"

import styles from "./Card.module.css";

interface CardProps {
    className?: string;
    title: string;
    description: string;
    image?: string;
    link?: string;
    linkText?: string;
}

export default ({ className, title, description, image, link, linkText}: CardProps) => {
    return (
        <div className={classNames(styles.root, className)}>
            <h3 className={styles.title}>{title}</h3>
            <p className={styles.description}>{description}</p>
            {link && (
                <Link href={link} className={styles.linkContainer}>
                    {linkText ?? "Read more"}
                </Link>
            )}
            {image && <img src={image} />}
        </div>
    );
};