import { h } from 'preact';
import { Box, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { useEffect, useRef, useState } from 'preact/hooks';
import { FC } from 'preact/compat';

const Styled = {
	Root: styled(Box)({
		width: '100%',
		height: '100%',
		padding: '10px',
		textAlign: 'center'
	}),
	Body: styled(Box)({
		display: 'flex',
		justifyContent: 'space-around',
		padding: '3px'
	})
};

interface ContestTitleProps {
	contest: Contest;
}

const ContestTitle: FC<ContestTitleProps> = ({ contest }) => (
	<Box>
		<Typography variant="h4" style={{ fontSize: '3vh' }}>{contest.title}</Typography>
		{contest.subtitle && <Typography variant="h6" style={{ fontSize: '1.5vh' }}>{contest.subtitle}</Typography>}
	</Box>
);

interface ContestProps {
	contest: Contest;
	contestants: any[];
	totalContests: number;
}

const Contest: FC<ContestProps> = ({ contest, contestants, totalContests }) => (
	<List>
		{contestants.filter(contestant => !!contestant[contest.id]).sort((contestant1, contestant2) => contestant1[contest.id] < contestant2[contest.id] ? 1 : -1).map(contestant => (
			<ListItem style={{ textAlign: 'center', background: 'rgba(255, 255, 255, 0.8)', width: `${80 / totalContests}vw`, padding: '0px' }} divider>
				<ListItemText primaryTypographyProps={{ color: 'primary', style: { fontSize: `${25 / contestants.length}vh` } }} secondaryTypographyProps={{ style: { fontSize: `${12 / contestants.length}vh` } }} primary={Math.floor(contestant[contest.id])} secondary={contestant.name} />
			</ListItem>
		))}
	</List>
);

interface Contest {
	title: string;
	subtitle?: string;
	id: string;
}

const Leaderboard = props => {
	const rootRef = useRef<HTMLElement>();
	const [contests] = useState<Contest[]>([
		{ title: 'Bench', id: 'bench' },
		{ title: 'Squat', subtitle: 'Back', id: 'squat-back' },
		{ title: 'Squat', subtitle: 'Front', id: 'squat-front' },
		{ title: 'SD', subtitle: '(Super Deker)', id: 'super-deker' }
	]);
	const [contestants] = useState([
		{ name: 'Alex', bench: 280, 'squat-back': 315, 'super-deker': 64 },
		{ name: 'Connor', bench: 305, 'squat-back': 335, 'super-deker': 60 },
		{ name: 'Colton', bench: 245 },
		{ name: 'Justin', bench: 305.1, 'squat-back': 390, 'squat-front': 315, 'super-deker': 76 },
		{ name: 'Nick', bench: 195, 'squat-back': 310 },
		{ name: 'Mom', bench: 105 }
	]);

	useEffect(() => {
		if (rootRef.current) {
			let opacity = 0;
			let direction = 1;
			const animationCallback = () => {
				const updateOpacity = () => {
					if (rootRef.current?.style) {
						opacity += direction * 0.0005;
						rootRef.current.style.background = `rgba(245,245,245, ${opacity})`;
					}
				};
				if (opacity >= 0.6 || opacity <= 0.0) {
					direction = opacity >= 0.6 ? -1 : 1;
					updateOpacity();
					setTimeout(animationCallback, 5000);
					return;
				}
				updateOpacity();
				requestAnimationFrame(animationCallback);
			};
			animationCallback();
		}
	}, [rootRef]);

	return (
		<Styled.Root ref={rootRef}>
			<Box style={{ padding: '10px', background: 'rgba(8, 122, 157, 0.8)', color: 'whitesmoke' }}>
				<Typography variant="h2" style={{ fontSize: '5vh' }}>Lifting Leaderboard @ 3001</Typography>
			</Box>
			<Styled.Body display="flex" >{contests.map(contest => <ContestTitle contest={contest} />)}</Styled.Body>
			<Styled.Body>
				{contests.map(contest => <Contest contest={contest} contestants={contestants} totalContests={contests.length} />)}
			</Styled.Body>
		</Styled.Root>
	);
};

export default Leaderboard;
