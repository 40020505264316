import { h, Fragment } from 'preact';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Router, Route } from 'preact-router';
import { useCallback, useContext, useLayoutEffect, useMemo, useState } from 'preact/hooks';
import styled from 'styled-components';

import DynamicPage from '~/components/DynamicPage';

import { StateContext, State } from '~/utilities/storage';
import { getUrl } from "~/utilities/url"
import { NotFound } from '~/components/NotFound';
import Overview from '~/routes/professional/overview';
import Education from '~/routes/professional/education';
import Projects from '~/routes/professional/projects';
import Interests from '~/routes/professional/interests';
import Employment from '~/routes/professional/employment';
import GameOfLife from '~/routes/personal/cellular/GOLInterface';
import Experiment from '~/routes/personal/cellular/ExperimentInterface';
import GiftIdeas from '~/routes/personal/gift-ideas';
import Leaderboard from '~/routes/personal/leaderboard';
import Spotify from '~/routes/personal/spotify';
import Youtube from '~/routes/personal/youtube';
import Recipes from '~/routes/personal/recipes';

import styles from "./App.module.css"

const theme = createTheme({
	palette: {
		primary: {
			main: 'rgb(8,122,157)'
		},
		secondary: {
			main: 'rgb(0,0,0)'
		}
	}
});

const Root = styled.div`
	box-sizing: border-box;
	min-height: 100%;
	width: 100%;
	background: radial-gradient(#172808, var(--light));
	font-family: "Roboto", sans-serif;
	:before {
		content: url('${getUrl("favicon.ico")}');
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
`;

const professionalPages = [
	{ name: "overview", body: Overview },
	{ name: "education", body: Education },
	{ name: "projects", body: Projects },
	{ name: "interests", body: Interests },
	{ name: "employment", body: Employment },
]

const ProfessionalRoute = () => {
	const currentState = useContext(StateContext);
	return <StateContext.Provider value={{ ...currentState, route: "/", numOfPages: professionalPages.length }}>
		{professionalPages.map(({ name, body }, index) => (
			<DynamicPage key={name} index={index} name={name} route={name} body={body} prefix="/" />
		))}
	</StateContext.Provider>
}

const personalPages = [
    { name: "youtube", body: Youtube },
    { name: "Game of Life", route: "gol", body: GameOfLife },
	{ name: "Experiment", route: "experiment", body: Experiment },
    { name: "spotify", body: Spotify },
    { name: "recipes", body: Recipes }
]

const PersonalRoute = () => {
	const currentState = useContext(StateContext);
	return <StateContext.Provider value={{ ...currentState, route: "/me/", numOfPages: personalPages.length }}>
		{personalPages.map(({ name, body, route = undefined }, index) => (
			<DynamicPage key={name} index={index} name={name} route={route} body={body} prefix="/me/" />
		))}
	</StateContext.Provider>
}


const App = () => {
	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	const [state, setState] = useState<State>({
		center: { x: 0, y: 0 },
		route: '/'
	});
	const handleRoute = useCallback(e => {
		if (state.activeRoute !== e.url) {
			setState({ ...state, activeRoute: e.url });
		}
	}, [state]);

	if (typeof window !== 'undefined') {
		useLayoutEffect(() => {
			const calculateWindowState = () => {
				const [width, height] = [window.innerWidth, window.innerHeight];
				const bounds = width > height ? height : width;
				setState(state => ({
					...state,
					center: {
						x: width / 2,
						y: height / 2
					},
					bounds
				}));
			};

			calculateWindowState();
			window.addEventListener('resize', calculateWindowState);
		}, []);
	}

	return useMemo(() => (
		<Root id="app" className={styles.root}>
			<ThemeProvider theme={theme}>
				<StateContext.Provider value={state}>
					<Router onChange={handleRoute}>
						<Route path="/:page*" component={ProfessionalRoute} />
						<Route path="/me/:page*" component={PersonalRoute} />
						<GiftIdeas path="/me/gift-ideas" />
						<Leaderboard path="/me/leaderboard" />
						<Route component={NotFound} default />
					</Router>
				</StateContext.Provider>
			</ThemeProvider>
		</Root>
	), [state]);
};
export default App;
