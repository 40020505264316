import { createContext } from 'preact';

export interface State {
    center?: {
        x: number;
        y: number;
    };
    bounds?: number;
    route?: string;
    activeRoute?: string;
    numOfPages?: number;
}

export const StateContext = createContext<State>({});