import { h } from "preact";
import { useMemo, useState } from "preact/hooks";

import { ExperimentGrid } from "./experiment";
import GridInterface from "./GridInterface";

const ExperimentInterface = () => {
    const [size, setSize] = useState(150)
    const [speed, setSpeed] = useState(50)
    const grid = useMemo(() => new ExperimentGrid(size), [size])
    return (
        <GridInterface size={size} grid={grid} speed={speed} />
    )
}

export default ExperimentInterface