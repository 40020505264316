import { Accordion, AccordionDetails, AccordionSummary, Box } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { h, Fragment } from 'preact';
import { useCallback, useMemo, useState } from 'preact/hooks';

export default (props) => {
    const [expand, setExpand] = useState([]);
    const onChange = useCallback(title => () => {
        if (expand.includes(title)) {
            setExpand(expand.filter(expandTitle => expandTitle !== title));
        }
        else {
            setExpand(expand.concat(title));
        }
    }, [expand]);

    const panels = useMemo(() => Array.isArray(props.panels) ?
        props.panels :
        Object.entries(props.panels).map(([name, content]) => ({ name, content }))
        , [props]);

    return (
        <Fragment>
            {panels.map(({ name, content, imgPath }) => (
                <Accordion expanded={expand.includes(name)} onChange={onChange(name)} >
                    <AccordionSummary expandIcon={<ExpandMore />} style={{}}>
                        <Box component="span" display="inline-flex" alignItems="center">
                            {imgPath && <Box component="img" src={imgPath} height="1rem" marginRight="5px" />}
                            {name}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        {content}
                    </AccordionDetails>
                </Accordion>
            ))}
        </Fragment>
    );
};