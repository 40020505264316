import { h } from 'preact';
import Recipes from './Recipes';
import Recipe from './recipe';
import { Router, Route } from 'preact-router';

export default () => (
    <Router>
        <Route path="/me/recipes" component={Recipes} />
        <Route path="/me/recipes/:slug" component={Recipe} />
    </Router>
);

