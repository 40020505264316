import { h, FunctionComponent } from 'preact';
import { useEffect, useRef, useState } from "preact/hooks"
import { RefreshCw, Play, Pause, Trash } from "preact-feather"

import styles from "./GridInterface.module.css"
import { Grid } from './base';

export interface GridInterfaceProps {
    size: number,
    speed?: number,
    grid: Grid
}

const GridInterface: FunctionComponent<GridInterfaceProps> = (props) => {
    const [speed, setSpeed] = useState(props.speed || 100)
    const [size] = useState(props.size)
    const [running, setRunning] = useState(true)
    const grid = useRef(props.grid)
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const rootRef = useRef<HTMLDivElement>(null)
    const ctx = useRef<CanvasRenderingContext2D>(null)
    const needsRedraw = useRef(true)

    const draw = () => {
        if (ctx.current && canvasRef.current && needsRedraw.current) {
            const canvas = canvasRef.current
            const spaces = grid.current.getAllSpaces()
            const spaceSize = canvas.width / size

            // clear canvas
            ctx.current.clearRect(0, 0, canvas.width, canvas.height)

            for (const space of spaces) {
                ctx.current.fillStyle = space.getColor()
                ctx.current.fillRect(space.x * spaceSize, space.y * spaceSize, spaceSize, spaceSize)
            }

            if (grid.current.focusedSpace) {
                ctx.current.strokeStyle = "red"
                ctx.current.strokeRect(grid.current.focusedSpace.x * spaceSize, grid.current.focusedSpace.y * spaceSize, spaceSize, spaceSize)
            }

            needsRedraw.current = false
        }

        window.requestAnimationFrame(draw)
    }

    const interact = (e: MouseEvent) => {
        const canvas = canvasRef.current
        if (canvas) {
            const rect = canvas.getBoundingClientRect()
            const x = e.clientX - rect.left
            const y = e.clientY - rect.top
            const spaceSize = canvas.width / size
            const spaceX = Math.floor(x / spaceSize)
            const spaceY = Math.floor(y / spaceSize)
            
            const isMouseMoveEvent = e.type === "mousemove"

            if (e.buttons === 1) {
                grid.current.toggleCell(spaceX, spaceY, isMouseMoveEvent)
            } else if (e.buttons === 0) {
                grid.current.setFocusedSpace(spaceX, spaceY)
            }
            needsRedraw.current = true
        }
    }

    const leave = () => {
        grid.current.clearFocusedSpace()
        needsRedraw.current = true
    }

    useEffect(() => {
        grid.current.reset()
    }, [])

    useEffect(() => {
        if (canvasRef.current && !ctx.current) {
            const canvas = canvasRef.current
            ctx.current = canvas.getContext("2d")

            const observer = new ResizeObserver(entries => {
                let { height, width } = entries[0].contentRect;
                height = height * 0.99
                width = width * 0.99
                let limiter = Math.min(height, width)
                limiter = limiter - (limiter % size)
                limiter = Math.floor(limiter)
                canvas.width = limiter
                canvas.height = limiter
                needsRedraw.current = true
            });
    
            observer.observe(rootRef.current);
        }
    }, [canvasRef.current])


    useEffect(() => {
        if (running) {                
            const interval = setInterval(() => {
                grid.current.step()
                needsRedraw.current = true
            }, speed)
            return () => clearInterval(interval)
        }
    }, [running, speed])

    useEffect(() => {
        window.requestAnimationFrame(draw)
    }, [])

    return (
        <div className={styles.root} ref={rootRef}>
            <div className={styles.viewport}>
                <div className={styles.controls}>
                    { running ? 
                        <Pause onClick={() => setRunning(false)} /> :
                        <Play onClick={() => setRunning(true)} />
                    }
                    <RefreshCw onClick={() => {
                        grid.current.reset()
                        needsRedraw.current = true
                    }} />
                    <Trash onClick={() => {
                        grid.current.reset(0)
                        needsRedraw.current = true
                    }} />
                </div>
                <canvas 
                    ref={canvasRef} 
                    onMouseDown={interact} 
                    onMouseMove={interact} 
                    onMouseLeave={leave}/>
            </div>
        </div>
    )
}

export default GridInterface