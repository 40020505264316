import { h } from 'preact';
import { getUrl } from "../../utilities/url"
import FullExpansion from "../../components/FullExpansion"

const panels = [
    {
        name: 'Force Therapeutics',
        role: 'Senior Software Engineer',
        details: <p>
            I work on just about all parts of the Provider and Patient web applications. The frontend is a lot of React that is very data-driven.
            The backend uses the Django framework tied to a MySQL database. A lot of the projects require updates throughout the full stack since
            the feature-scape is rapidly expanding. The team I work on is especially focused on data integrations with other systems any of Force's
            partner health systems use. The main goals of all the work we do, as I see it, is to provide as much transparency as possible on the care
            that patients receive in their health journey to all involved parties. That involves the patient, their providers, and the staff of the
            healthcare systems that make the care the patients receive possible.
        </p>,
        content: <div>
            <h3>Senior Software Engineer @ Force Therapeutics</h3>
            <small>August 2021 - Now</small>
            <p>
                I work on just about all parts of the Provider and Patient web applications. The frontend is a lot of React that is very data-driven.
                The backend uses the Django framework tied to a MySQL database. A lot of the projects require updates throughout the full stack since
                the feature-scape is rapidly expanding. The team I work on is especially focused on data integrations with other systems any of Force's
                partner health systems use. The main goals of all the work we do, as I see it, is to provide as much transparency as possible on the care
                that patients receive in their health journey to all involved parties. That involves the patient, their providers, and the staff of the
                healthcare systems that make the care the patients receive possible.
            </p>
        </div>,
        imgPath: getUrl("assets/images/force.jpg"),
        timeline: "August 2021 - Now",
    },
    {
        name: 'Best Buy',
        role: 'Software Engineer',
        details: <p>
            DAI is responsible for most of the 3rd party integrations, as well as analytics related to user interactions with the core <a href="https://www.bestbuy.com">BestBuy.com</a> experience.
            I've worked the most on the client-side analytics framework that connects data gathered by UI components with reporting and recommendations systems on the backend.
            Although pretty much all of the code that goes onto the website from DAI is non-visual, we have built an internal tool as a chrome
            extension to visualize the data being processed by our team on any given Best Buy page.
            The main technologies we work with are ES6+ JavaScript, npm/yarn, Babel, Webpack, Preact, MaterialUI, and WebdriverIO.
        </p>,
        content: <div>
            <h3>Software Engineer @ Best Buy - DAI (Digital Analytics implementation)</h3>
            <small>July 2019 - August 2021</small>
            <p>
                DAI is responsible for most of the 3rd party integrations, as well as analytics related to user interactions with the core <a href="https://www.bestbuy.com">BestBuy.com</a> experience.
                I've worked the most on the client-side analytics framework that connects data gathered by UI components with reporting and recommendations systems on the backend.
                Although pretty much all of the code that goes onto the website from DAI is non-visual, we have built an internal tool as a chrome
                extension to visualize the data being processed by our team on any given Best Buy page.
                The main technologies we work with are ES6+ JavaScript, npm/yarn, Babel, Webpack, Preact, MaterialUI, and WebdriverIO.
            </p>
        </div>,
        imgPath: getUrl("assets/images/BBY.png"),
        timeline: "July 2019 - August 2021",
    },
    {
        name: 'Epic Systems',
        role: 'Software Developer',
        details: <p>
            The goal of the team I was a part of was to maintain and improve the predictive model pipeline, which turned clinical data into actionable insights. Also, we started to build out the cloud infrastructure to improve efficiency, and generalize for use cases outside the scope of predictive analytics.
            A few specific projects I worked on were:
            <ul>
                <li>Create a path for customers to use an existing clinical predictive modeling workflow using their own platform</li>
                <li>Take a NodeJS library that was being used by many services in a Kubernetes Cluster, and turn it into its own service offering the same functionality via a RESTful API</li>
                <li>Integrate multiple pieces of new functionality created by other developers into the existing predictive modeling pipeline</li>
            </ul>
        </p>,

        content: <div>
            <h3>
                Software Developer @ Epic Systems - Cognitive Computing Platform
            </h3>
            <small>June 2018 - May 2019</small>
            <div>
                The goal of the team I was a part of was to maintain and improve the predictive model pipeline, which turned clinical data into actionable insights. Also, we started to build out the cloud infrastructure to improve efficiency, and generalize for use cases outside the scope of predictive analytics.
                A few specific projects I worked on were:
                <ul>
                    <li>Create a path for customers to use an existing clinical predictive modeling workflow using their own platform</li>
                    <li>Take a NodeJS library that was being used by many services in a Kubernetes Cluster, and turn it into its own service offering the same functionality via a RESTful API</li>
                    <li>Integrate multiple pieces of new functionality created by other developers into the existing predictive modeling pipeline</li>
                </ul>
            </div>
            <h3> Software Developer Intern @ Epic Systems - Genomics</h3>
            <small>Summer of 2017</small>
            <div>
                For my internship at Epic I had a single project for the summer which was to create a
                web application that genetic specialists (Bioinformaticians, Genetic Analysts, etc.) could use to aid
                in the process of interpreting VCF files. In the process, I worked with experienced developers,
                quality assurance individuals, and UX designers to create the final web application which was called the
                Variant Explorer. The list of features is as follows:
                <ul>
                    <li >Import VCF file and perform checks to ensure proper formatting</li>
                    <li >Search and Filter variants based on any associated field</li>
                    <li >Dig into any information associated with a variant</li>
                    <li >Annotate individaul variants to aid in clinical decisions down the road</li>
                    <li >Save out subsets of the file</li>
                </ul>
            </div>
        </div>,
        imgPath: getUrl("assets/images/epic.webp"),
        timeline: "June 2018 - May 2019, Summer of 2017",
    },
    {
        name: 'Thomson Reuters',
        role: 'Software Developer Intern',
        content: <div>
            <h3>
                Software Developer Intern @ Thomson Reuters
            </h3>
            <small>Summer of 2016</small>
            <p>
                At Thomson Reuters I worked with a small team of developers to maintain and upgrade an internal web application. The
                application was used by an expanding group of Thomson Reuters employees to take a database of internal information and
                generate reports in the form of excel documents that represented some useful subset of the data that could be used to
                improve their workflows.
            </p>
            <p>
                There were also a few interactive, real-time tools for visualizing the internal information,
                one of which I created during the ladder portion of the summer. The tool I made allowed the user to visualize and interact
                with data through the use of interactive pie charts. I used the Google Visualization JavaScript API to help create the tool.
            </p>
            <p>
                Other tasks included creating templates for SQL queries that users could change specific parameters to customize and receive
                the results in an excel document, and creating Python scripts that helped connect the SQL database with a sharepoint site.
            </p>
        </div>,
        imgPath: getUrl("assets/images/TRI.png"),
        timeline: "Summer of 2016",
    },
    {
        name: 'University of Minnesota',
        role: 'Teaching Assistant',
        content: <div>
            <h3>
                Teaching Assistant @ University of Minnesota
            </h3>
            <small>Each Fall and Spring semester from 2015 through 2017</small>
            <p>
                As a TA I was tasked with working with students at labs and office hours to help them learn the concepts presented in class,
                assist in the grading process, and help advise the professor in how to help the students the most.
            </p>
            <p>
                For the 2015-2016 school year I was a TA for the CSCI 1113 course titled
                <em>Intro to C/C++ Programming</em>. This was an
                introductory course to computer programming so it covered the basics such as types, control flow, and loops. Slightly more
                advanced topics were also looked into such as pointers and OOP basics.
            </p>
            <p>
                For the 2016-2017 school year I was a TA for the CSCI 2041 course titled
                <em>Advanced Programming Principles</em>. This course
                was an intermediate level course taught through the medium of functional programming. One semester used Clojure, and the other
                used Ocaml. Topics such as inferred vs. hard typing, higher-order functions, and closures were covered.
            </p>
        </div>,
        imgPath: getUrl("assets/images/uofm.jpg"),
        timeline: "Each Fall and Spring semester from 2015 through 2017",
    }
]

export default () => <FullExpansion panels={panels} />