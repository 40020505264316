import { h } from 'preact';
import FullExpansion from "~/components/FullExpansion";

const panels = {
    'Artificial Intelligence/Machine learning': <div>
        Making computers act intelligently has always been an interest of mine.
        I find automation to be a very useful tool, and Artificial Intelligence opens the door for
        automation of complex tasks. Machine learning is a powerful tool for aiding in the
        progression of Artificial Intelligenece as it allows for complex patterns to be found
        and leveraged.
    </div>,
    'Data Visualization': <div>
        Often times it seems that there's a lot of data that could tell us something
        extemely useful, but looking at the raw data isn't useful for grasping these concepts. Data visualization
        can tell the story behind data in a fun and intuitive manner and I think this is an area that
        will become more and more useful as data gets bigger and bigger.
    </div>,
    'Cellular Automata': <div>
        Cellular Automata are extremely interesting for me, and largely so
        because we don't seem to know much about them. Hobbyists have played around with them with things such as
        Conway's Game of Life (myself included), but there hasn't been much in the terms of practicul use. I
        think that Cellular Automata have the potential to open some doors that we can't see yet. Specifically,
        file compression could possibly be improved by storing states and then decompressing by running those
        states to receive the original data back.
    </div>,
    Blockchain: <div>
        With the rise of Bitcoin and other cryptocurrencies it's hard to ignore the
        potential that blockchain technologies have to change the way our technological world works. They
        offer decentralized control at a massive scale and possibly many more benefits as we understand
        the capabilites of blockchains better.
    </div>
}

export default () => <FullExpansion panels={panels} />