import classNames from "classnames";
import { h } from "preact";
import { Link } from "preact-router";

import styles from "./Link.module.css";

export default ({ href, children, className }) => (
    <Link className={classNames(styles.root, className)} href={href}>
        {children}
    </Link>
);