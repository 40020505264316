import { Cell, CellOptions, Grid, Influence, Space } from "./base";

interface Momentum {
    x: number;
    y: number;
}

interface GeneticCellOptions extends CellOptions {
    geneticCode?: string;
}

class GeneticCell extends Cell {
    geneticCode: string;
    geneticCodeLength: number = 17;
    // number of neighbors required for birth
    birthCount: number;
    // steps left until death
    deathCount: number;
    // neighbor visitation order
    visitationOrder: number[] = [0, 1, 2, 3, 4, 5, 6, 7];
    energy: number = 0;

    generateOffspring(otherCell?: GeneticCell): GeneticCell {
        let geneticCode = this.geneticCode;
        if (otherCell) {
            for (let i = 0; i < this.geneticCodeLength; i++) {
                geneticCode += Math.random() < 0.5 ? this.geneticCode[i] : otherCell.geneticCode[i];
            }
        }

        for (let i = 0; i < this.geneticCodeLength; i++) {
            // mutation chance is 1/256
            if (Math.random() < 1 / 256) {
                geneticCode = geneticCode.slice(0, i) + (geneticCode[i] === "0" ? "1" : "0") + geneticCode.slice(i + 1);
            }
        }

        return new GeneticCell({ geneticCode });
    }

    getBirthCount(): number {
        // interpret first two bits as binary number
        return parseInt(this.geneticCode.slice(3, 5), 2);
    }

    getDeathCount(): number {
        return parseInt(this.geneticCode.slice(7, 10), 2);
    }

    constructor(options: GeneticCellOptions = {}) {
        super(options);
        this.geneticCode = options.geneticCode;
        if (!this.geneticCode) {
            // generate random genetic code
            this.geneticCode = "";
            for (let i = 0; i < this.geneticCodeLength; i++) {
                this.geneticCode += Math.random() < 0.5 ? "0" : "1";
            }
        }
        this.birthCount = this.getBirthCount();
        this.deathCount = this.getDeathCount();
        // visitaiton order based on genetic code
        for (let i = 0; i < 8; i++) {
            const idx = (i * 2);
            this.visitationOrder[i] = parseInt(this.geneticCode.substring(idx, idx + 3), 2);
        }

        this.energy = 1;
        
        this.color = `rgb(${parseInt(this.geneticCode.slice(0, 4), 2) * 16}, ${parseInt(this.geneticCode.slice(4, 8), 2) * 16}, ${parseInt(this.geneticCode.slice(8, 12), 2) * 16})`;
        this.updator = (space) => {
            this.energy += space.influence.amount;
            if (this.deathCount > 0) {
                this.deathCount -= 1;
                const neighborCellCount = space.neighbors.filter((neighbor) => neighbor.cell instanceof GeneticCell).length;
                if (neighborCellCount === this.birthCount) {
                    for (const idx of this.visitationOrder) {
                        if (this.energy <= 1) {
                            break;
                        }
                        const neighbor = space.neighbors[idx];
                        if (!neighbor.cell) {
                            neighbor.cell = this.generateOffspring();
                            this.energy -= 1;
                        }
                    }
                }
                for (const idx of this.visitationOrder) {
                    if (this.energy <= 0) {
                        break;
                    }
                    const neighbor = space.neighbors[idx];
                    if (neighbor.cell instanceof EnergyCell) {
                        this.energy -= 1;
                        this.energy += neighbor.cell.influence.amount;
                        neighbor.cell = this;
                        space.cell = undefined;
                        break;
                    }
                }
            } else {
                // death
                space.cell = undefined;
            }
        }

        // console.log(`
        //     birthCount: ${this.birthCount}
        //     deathCount: ${this.deathCount}
        //     visitationOrder: ${this.visitationOrder.join(",")}
        // `)
    }

    exertInfluence(space: Space) {
        space.influence.updator = this.updator;
    }
}

interface EnergyCellOptions {
    momentum?: Momentum;
}

class EnergyCell extends Cell {
    momentum: Momentum;

    constructor(options: EnergyCellOptions = {}) {
        super();
        this.momentum = options.momentum || { x: 0, y: 0 };
        // light yellow
        this.color = `rgb(255, 255, 204)`;
        // debug for direction of energy
        //this.color = `rgb(${Math.abs(this.momentum.x) * 255}, ${Math.abs(this.momentum.y) * 255}, 204)`;
        this.influence = new Influence(2);
    }

    exertInfluence(space: Space): void {
        if (this.momentum.x === 0 && this.momentum.y === 0) {
            return;
        }
        let spaces = [{
            space: space.getNeighbor(this.momentum.x, this.momentum.y),
            updator: (neighbor) => {
                neighbor.cell = this
                if (space.cell === this) {
                    space.cell = undefined;
                }
            }
        }]
        if (this.momentum.x !== 0 && this.momentum.y !== 0) {
            spaces.push({
                space: space.getNeighbor(this.momentum.x, 0),
                updator: (neighbor) => {
                    neighbor.cell = new EnergyCell({ momentum: { x: this.momentum.x, y: 0 } });
                }
            });
            spaces.push({
                space: space.getNeighbor(0, this.momentum.y),
                updator: (neighbor) => {
                    neighbor.cell = new EnergyCell({ momentum: { x: 0, y: this.momentum.y } });
                }
            });
        }
        spaces.forEach(({space, updator}) => {
            if (space.cell instanceof GeneticCell) {
                space.influence.add(this.influence);
            } else if (!space.cell || space.cell instanceof EnergyCell) {
                if (!space.influence.updator || Math.random() < 0.5) {
                    space.influence.updator = updator
                }
            }
        });
    }
}


class SunCell extends Cell {
    constructor(options = {}) {
        super(options);
        this.color = "yellow";
        this.influence = new Influence(3);
    }

    exertInfluence(space: Space): void {
        for (let x = -1; x <= 1; x++) {
            for (let y = -1; y <= 1; y++) {
                if (x === 0 && y === 0) {
                    continue;
                }
                const neighbor = space.getNeighbor(x, y);
                if (!neighbor) {
                    continue;
                }
                if (neighbor.cell instanceof GeneticCell) {
                    neighbor.influence.add(this.influence);
                } else if (!neighbor.cell || neighbor.cell instanceof EnergyCell) {
                    neighbor.influence.updator = (space) => {
                        space.cell = new EnergyCell({ momentum: { x, y } });
                    }
                }
            }
        }
    }
}

export class ExperimentGrid extends Grid {
    constructor(size) {
        super(size);
    }

    getNewCell() {
        return new GeneticCell();
    }

    toggleCell(i, j, onlyAdd: boolean = false) {
        if (onlyAdd && this.spaces[i][j].cell) {
            return;
        }
        if (this.spaces[i][j].cell) {
            this.spaces[i][j].cell = undefined;
            return;
        }
        this.spaces[i][j].cell = this.getNewCell()
    }

    reset(density = 0.1) {
        this.getAllSpaces().forEach((space) => {
            space.reset();
            if (Math.random() < density) {
                space.cell = this.getNewCell();
            } else {
                space.cell = new EnergyCell();
            }
        });
        const center = Math.floor(this.size / 2);
        this.spaces[center][center].cell = new SunCell();
    }
}