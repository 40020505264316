import { h } from "preact";
import { useMemo, useState } from "preact/hooks";

import { GOLGrid } from "./gol";
import GridInterface from "./GridInterface";

const GOLInterface = () => {
    const [size, setSize] = useState(100)
    const grid = useMemo(() => new GOLGrid(size), [size])
    return (
        <GridInterface size={size} grid={grid} />
    )
}

export default GOLInterface