export type Updator = (space: Space) => void;

const updators: { [key: string]: Updator } = {
    identity: (space: Space) => {}
}


export class Influence {
    public amount: number
    public updator?: Updator;

    constructor(amount: number = 0) {
        this.amount = amount
    }

    add(influence: Influence) {
        this.amount += influence.amount
    }

    clear() {
        this.amount = 0
        this.updator = undefined
    }
}

export class Space {
    cell?: Cell;
    neighbors: Space[] = [];
    neighborsMap: { [key: string]: Space } = {};
    influence: Influence = new Influence();
    x: number;
    y: number;

    constructor(x: number = 0, y: number = 0) {
        this.x = x
        this.y = y
    }

    getColor() {
        return this.cell?.getColor() || "white"
    }

    getNeighbor(x: number, y: number) {
        return this.neighborsMap[`${x},${y}`]
    }

    exertInfluence() {
        if (this.cell) {
            this.cell.exertInfluence(this)
        }
    }

    step() {
        this.influence.updator?.(this)
        this.influence.clear()
    }

    reset() {
        this.cell = undefined
        this.influence.clear()
    }
}

export interface CellOptions {
    influence?: Influence
}

const getColorGradient = (amount: number) => {
    const gradient = Math.floor(255 - ((amount - 0.9) * 5 * 100))
    return `rgb(${gradient}, ${gradient}, ${gradient})`
}

export class Cell {
    influence: Influence;
    color: string;
    updator: Updator;

    constructor(options: CellOptions = {}) {
        this.influence = options.influence || new Influence(1)
        this.color = "black"
        this.updator = updators.identity
    }

    getColor() {
        return this.color
    }

    exertInfluence(space: Space) {
        space.neighbors.forEach((space) => {
            // @ts-ignore
            space.influence.add(this.influence)
            if (!space.influence.updator || Math.random() < 0.5) {
                space.influence.updator = this.updator
            }
        })
    }
}

export class Grid {
    size: number
    spaces: Space[][]
    focusedSpace?: Space

    constructor(size) {
        this.size = size
        this.spaces = []
        for (let i = 0; i < size; i++) {
            this.spaces.push([])
            for (let j = 0; j < size; j++) {
                this.spaces[i].push(new Space(i, j))
            }
        }
        for (let i = 0; i < size; i++) {
            for (let j = 0; j < size; j++) {
                this.addNeighbors(i, j)
            }
        }
    }

    getAllSpaces() {
        return this.spaces.flat()
    }

    reset(density: number = 0.5) {
        this.getAllSpaces().forEach((space) => {
            if (Math.random() < density) {
                space.cell = this.getNewCell()
            } else {
                space.cell = undefined
            }
        })
    }

    getSpace(i, j) {
        return this.spaces[i][j]
    }

    setSpace(i, j, space: Space) {
        this.spaces[i][j] = space
    }

    setFocusedSpace(i, j) {
        this.focusedSpace = this.spaces[i][j]
    }

    clearFocusedSpace() {
        this.focusedSpace = undefined
    }

    toggleCell(i, j, onlyAdd: boolean = false) {
        if (!onlyAdd) {
            this.spaces[i][j].cell = undefined
            return
        }
        this.spaces[i][j].cell = this.getNewCell()
    }

    addNeighbors(i, j) {
        const size = this.size
        for (let x = -1; x <= 1; x++) {
            for (let y = -1; y <= 1; y++) {
                if (x === 0 && y === 0) {
                    continue
                }
                const neighbor = this.spaces[(i + x + size) % size][(j + y + size) % size]
                this.spaces[i][j].neighbors.push(neighbor)
                this.spaces[i][j].neighborsMap[`${x},${y}`] = neighbor
            }
        }
    }

    step() {
        this.spaces.forEach((row) => {
            row.forEach((space) => {
                space.exertInfluence()
            })
        })
        this.spaces.forEach((row) => {
            row.forEach((space) => {
                space.step()
            })
        })
    }

    getNewCell() {
        return new Cell()
    }
}