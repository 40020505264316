import { h } from 'preact';
import styled from 'styled-components'
import { useEffect, useState } from 'preact/hooks';
import { getApiUrl } from '../../utilities/url'

const Root = styled.div`
	width: 100%;
	height: 100vh;
	padding: 10px;
	background: rgba(255,255,255, 0.6);
	text-align: center;
	position: relative;
`

const Gift = styled.div`
	margin: auto;
	max-width: 500px;
	margin-top: 10px;
	background: rgba(255,255,255, 0.6);
	border-radius: 5px;
	padding: 10px;
`

const Button = styled.button`
	cursor: pointer;
	border: solid white 2px;
	border-radius: 3px;
	padding: 4px;
	text-transform: capitalize;
	background: transparent;
`

const GiftIdeas = props => {
	const [giftIdeas, setGiftIdeas] = useState([]);
	useEffect(() => {
		(async () => {
			setGiftIdeas(await (await fetch(getApiUrl('/api/gift-ideas/'))).json());
		})();
	}, []);

	return (
		<Root>
			<h1>Gift Ideas</h1>
			{giftIdeas.map(idea => (
				<Gift>
					<h2>{idea.name}</h2>
					<p>{idea.comments}</p>
					<a href={idea.url} target="_blank" >
						<Button>See example</Button>
					</a>
				</Gift>
			))}
		</Root>
	);
};

export default GiftIdeas;
