import { h } from 'preact';
import styled from 'styled-components';
import { getUrl } from "~/utilities/url";

const Root = styled.div`
    padding: 15px;
    background: rgba(255, 255, 255, 0.5);
`

const LineLinkRoot = styled.span`
    display: inline-flex; 
    align-items: center;
    border-radius: 5px;
    margin: 0px 2px;
    padding: 0px 5px;
    background-color: whitesmoke;
    filter: drop-shadow(0 0 0.25rem ${props => props.accentColor});
`

const LineLinkImage = styled.img`
    height: 1rem;
    margin-right: 5px;
`

const LineLink = props => (
    <LineLinkRoot accentColor={props.accentColor}><LineLinkImage src={props.imgPath} />{props.name}</LineLinkRoot>
);

export default () => (
    <Root>
        <h3>Story so far</h3>
        <br />
        <p>
            While attending the <LineLink imgPath={getUrl("assets/images/uofm.jpg")} name="University of Minnesota - Twin Cities" accentColor="maroon" />,
            I worked part time as a Teaching Assistant in entry to intermediate
            level Computer Science courses. I had to really nail down programming basics, both Object-oriented and functional, since I would
            otherwise not be a very helpful Teaching Assistant.
        </p>
        <br />
        <p>
            During the summer between my Sophomore and Junior year of university, I was
            lucky enough to land an internship with <LineLink imgPath={getUrl("assets/images/TRI.png")} name="Thomson Reuters" accentColor="darkorange" />. There I got my first taste of what working as a software engineer in the
            industry was like. I worked on multiple projects that exposed me to scripting, web applications, database queries, and a little bit
            of backend.
        </p>
        <br />
        <p>
            The following summer, I took an opportunity to live outside my hometown for a bit and intern at <LineLink imgPath={getUrl("assets/images/epic.webp")} name="Epic Systems" accentColor="red" /> in the Madison
            WI area. There I worked on a single project which was to build a web application for Genetic Analysts. The environment and tooling
            requirements were hard to nail down, and with my mentor team wanting to leave things as open ended as possible for me, I ended up
            writing a Pure JS web application built with IE support in mind. In hindsight, a framework such as React would've made things a lot
            easier, but I also appreciate the depth of knowledge I gained of JavaScript and DOM manipulation basics that I use from time to time
            still.
        </p>
        <br />
        <p>
            After graduating from the U of M, I ended up taking my first full time position at <LineLink imgPath={getUrl("assets/images/epic.webp")} name="Epic Systems" accentColor="red" /> based on my great experience as an intern.
            I was placed on the Cognitive Computing Platform team. My team dealt with
            connecting healthcare workflows within the EMR (Electronic Medical Record) system to predictive models so that time-sensitive patient outcomes,
            such as the risk of Sepsis, could be given attention as fast as possible. During the year I spent at Epic Systems, I worked on two different sub-teams.
            The first sub-team was responsible for the User-facing workflow that healthcare analysts would use to configure charts that frontline workers
            used daily to include the outputs of the predictive models on a per-patient basis. The work I did was mostly on the backend, and revolved around
            managing data I/O from the old NoSQL-style database that was used throughout Epic's EMR system. The second sub-team I worked on was much more interesting
            to me. On that team, we were working directly on the pipeline that would collect patient data, run the appropriate predictive model, and feed the data
            back into the EMR system, all from an external Kubernetes cluster hosted in Microsoft's Azure. Working with microservices with NodeJS and Go was
            an entirely new world to me, but it only took a month or so before I started to cognize all the advantages of the architecture style. Unfortunately,
            having the entirety of my family and social circle being a 4 hour drive away ended up pushing me away from Epic and the interesting work I was doing.
        </p>
        <br />
        <p>
            In my transition back to the Twin Cities area, I found myself working at the corporate office of one of my favorite retailers,  <LineLink imgPath={getUrl("assets/images/BBY.png")} name="Best Buy" accentColor="blue" />, where I still
            am to this day. The team I work on, DAI (Digital Analytics Implementation), deals with technology that is right on par with my experience coming into the job.
            It's largely systems and api building, but it all lives on the client of bestbuy.com. My experience of using JavaScript and NodeJS in a browser environment
            has paired nicely with the data-oriented backend systems I worked with at Epic. In addition to the systems and api building, I've also gotten exposure to
            another whole world of development, React. My only previous professional experience with UI/UX development coming into Best Buy was in PureJS/CSS/HTML, other
            than a small demo UI back at Epic that used a little bit of Vue.js. I had done some work with Vue.js on my personal website, but it was all one off kind of things
            and really just gave me a rough idea of how modern frontend frameworks functioned. Shortly after I joined DAI at Best Buy I was trying to find ways to make the
            validation process for our work easier since it mostly involved opening the Network tab of the Chrom dev tools and looking for specific network requests. I stumbled
            upon a project started by someone who had since moved teams within Best Buy, and it was a basic Chrome Extension that used Preact (pretty much React) to display the
            network call data as an overlay on the website. I was intruiged by this idea, and started building out a more comprehensive tool to visualize all the different
            aspect of our client framework to help my team, and other teams we worked with, track how data was flowing through our system. After less than a year it was hit
            and what is now formally known as DAILight was born. You won't find this extension in the web store since it's an internal Best Buy tool.
        </p>
    </Root>
);