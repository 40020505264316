import { useEffect, useMemo, useState } from 'react';

import { getApiUrl } from '~/utilities/url';

import { RecipeJSON } from './types';

const recipeCache = []

export default () => {
    const [loading, setLoading] = useState(true);
    const [recipes, setRecipes] = useState<RecipeJSON[]>([]);
    const [selectedRecipeSlug, setSelectedRecipeSlug] = useState<string>("");
    const selectedRecipe = useMemo(() => 
        recipes.find(recipe => recipe.slug === selectedRecipeSlug), 
    [recipes, selectedRecipeSlug]);
    
	useEffect(() => {
        if (recipeCache.length) {
            setRecipes(recipeCache);
            setLoading(false);
            return;
        }
		const fetchRecipes = async () => {
			const data = await (await fetch(getApiUrl('/api/recipes/'))).json();
			setRecipes(data);
            recipeCache.push(...data);
			setLoading(false);
		};

		fetchRecipes();
	}, []);

    return {
        loading,
        recipes,
        selectedRecipe,
        setSelectedRecipeSlug,
    };
}
